<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  import Gen from '@helper/Gen';
  import draggable from 'vuedraggable'

  export default {
    name: "BoProductCat",
    extends: Base,
    components: {
      draggable
    },
    data() {
      return {
        Name: "BoProductCat",
        row1: {},
        product_hero: [],
        font_style: {},
        font_size: {},
        sub_font_size: {},
        mpTitleLength:0,
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData(() => {
        setTimeout(() => {
          this.$set(this.row, 'type', 'update')
        }, 500)
      })
    },
    methods: {
      submitHero() {
        BOGen.apirest('/' + this.Name, {
          data: this.row1,
          type: this.row1.type
        }, (err, resp) => {
          if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.hero-info')
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, '.hero-info')
            this.refreshData()
            this.row1 = {}
            setTimeout(() => {
              $('#collapseExample').hide()
            }, 2000)
          }
        }, "POST");
      },
      addSlide() {
        this.row1 = {
          ahh_is_active: "Y"
        }
        this.row1.type = 'addHerro'
      },
      editSlide(v) {
        $('#collapseExample').css("display", "flex")
        this.row1 = v;
        this.row1.type = 'updateHero'
      },
      submitOverview() {
        BOGen.apirest('/' + this.Name, this.row2, (err, resp) => {
          if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.col-info')
          if (resp.success) {
            Gen.info(resp.message, 'success', 2000, '.col-info')
            this.refreshData()
            setTimeout(() => {
              $('#collapseExample2').hide()
            }, 2000)
          }
        }, "POST");
      },
      editOverview(v) {
        $('#collapseExample2').css("display", "flex")
        console.log("edit overview")
        this.row2 = v
        this.row2.type = 'updateOverview'
      },
      endDrag() {
        BOGen.apirest('/' + this.Name, {
          data: this.product_hero,
          type: 'sort'
        }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
      endDragCat() {
        BOGen.apirest('/' + this.Name, {
          data: this.data.data,
          type: 'sortCat'
        }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
      closeDisplay() {
        $('#collapseExample').css("display", "none")
      },
      viewBanner(v) {
        $('#viewbanner').modal();
        $('#viewbanner').find('.img1').attr('src', v.image_bg)
        $('#viewbanner').find('.img2').attr('src', v.image_fg)
      }
    },
    watch: {
      '$route.query'(v) {
        this.refreshData()
      },
      'row.mpc_name'(v){
        this.mpTitleLength = v.length
      }
    }
  }
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="card" v-if="!$route.params.id">
      <div class="card-header">

        <h5 class="card-title">Slide Show</h5>
      </div>
      <div class="card-body">
        <div class="alert alert-info">
          Image Requirements for Slide Show :
          <ul class="mb-0 mt-2">
            <li><strong>Dimension: </strong><span>1350x550px (Desktop), 768x1285px (Mobile)</span></li>
            <li><strong>Format: </strong><span>jpeg,jpg,png</span></li>
            <li><strong>Max. Size: </strong><span>2 MB</span></li>
          </ul>
        </div>
        <draggable v-model="product_hero" class="row" tag="tbody" @end="endDrag">

          <div style="cursor:move;" class="col-md-3" :id="'dat'+v.aph_id" v-for="(v,k) in product_hero" :key="k">
            <div class="wrap_slider_img">
              <img :src="uploader(v.aph_img,'350')" class="img-responsive" />
              <div class="slider_name">
                <p>{{v.aph_title}}</p>
              </div>
              <a data-toggle="collapse" @click="editSlide(v)" href="javascript:;" class="bullet_edit"><i
                  class="ti-marker-alt"></i></a>
              <!-- <a href="javascript:;" @click="deleteItemId(v.aph_id,'dat'+v.aph_id)" class="bullet_delete"><i
                  class="far fa-trash-alt"></i></a> -->
              <span class="label label-success" v-if="v.aph_is_active == 'Y'">Active</span>
              <span class="label label-danger" v-else>Inactive</span>
            </div>
          </div>

          <div class="col-md-3" v-if="product_hero.length < 2">
            <a class="wrap_upload" data-toggle="collapse" @click="addSlide" href="#collapseExample" role="button"
              aria-expanded="false" aria-controls="collapseExample">
              <div class="ic_wrap">
                <i class="fas fa-plus"></i>
                <p>New Slider</p>
              </div>
            </a>
          </div>
        </draggable>
        <VForm @resp="submitHero" method="post">
          <div class="row collapse mt-4" id="collapseExample">
            <div class="col-12 hero-info"></div>
            <div class="col-md-8 mb-3">
              <BoField name="aph_img" mandatory>
                <Uploader :param="{thumbnail:true}" name="aph_img" v-model="row1.aph_img" type="hero_desktop"
                  uploadType="cropping"></Uploader>
              </BoField>
              <BoField name="aph_title" v-model="row1.aph_title"></BoField>
              <BoField name="aph_sub_title" v-model="row1.aph_sub_title"></BoField>
              <BoField name="aph_link" :attr="{type:'url'}" v-model="row1.aph_link"></BoField>
              <BoField name="aph_is_active">
                <div class="row">
                  <div class="col-md-2">
                    <radio name="aph_is_active" v-model="row1.aph_is_active" option="Y"
                      :attr="validation('aph_is_active')">Active</radio>
                  </div>
                  <div class="col-md-2">
                    <radio name="aph_is_active" v-model="row1.aph_is_active" option="N">Inactive</radio>
                  </div>
                </div>
              </BoField>
            </div>
            <div class="col-md-4 mb-3">
              <BoField name="aph_img_mobile" mandatory>
                <Uploader :param="{thumbnail:true}" name="aph_img_mobile" v-model="row1.aph_img_mobile"
                  type="hero_mobile" uploadType="cropping"></Uploader>
              </BoField>
            </div>

            <div class="col-12 mt-3 text-right">
              <a href="javascript:;" @click="closeDisplay" class="btn btn-rounded btn-danger m-r-5">Close</a>
              <button type="submit" class="btn btn-rounded btn-info btn-loading">Submit Form</button>
            </div>
          </div>
        </VForm>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <VForm @resp="search">
          <div class="row">
            <div class="col-sm-5">
              <h5 class="card-title">{{ObjectName}} List</h5>
            </div>
            <div class="col-sm-3"></div>
            <div class="col-sm-3">
              <div class="form-group mb-0">
                <div class="input-group">
                  <input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
                    placeholder="Search...">
                  <div class="input-group-append">
                    <button class="btn btn-info" type="button" @click="search()">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-1">
              <router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
            </div>
          </div>
        </VForm>
      </div>
      <div class="card-body">
        <div class="alert alert-info">
          Image Requirements for Product Category Banner :
          <ul class="mb-0 mt-2">
            <li><strong>Dimension: </strong><span>1350x550px (Background), 545x360px (Object)</span></li>
            <li><strong>Format: </strong><span>jpeg,jpg,png</span></li>
            <li><strong>Max. Size: </strong><span>2 MB</span></li>
          </ul>
        </div>
        <div class="row" v-if="!$route.params.id">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="table-responsive ajax-table">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{fields.mpc_name}}</th>
                    <th>{{fields.mpc_hero_image}}</th>
                    <th>{{fields.mpc_is_active}}</th>
                    <th width="120px">Action</th>
                  </tr>
                </thead>
                <draggable v-model="data.data" tag="tbody" @end="endDragCat" style="cursor:pointer;">
                  <tr v-for="(v,k) in data.data" :key="k">
                    <td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
                    <td>{{v.mpc_name}}</td>
                    <td>
                      <a href="javascript:;" class="btn btn-success m-l-15" @click="viewBanner(v)"><i
                          class="fa fa-image  m-r-15"></i>View Banner </a>
                    </td>
                    <td>
                      <StatusLabel :status="v.status"></StatusLabel>
                    </td>
                    <td class="btn-action">
                      <router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}"
                        v-tooltip="'Edit'">
                        <i class="ti-marker-alt"></i>
                      </router-link>
                      <a v-if="page.moduleRole('ChangeStatus')" href="javascript:;" class="icon_action"
                        @click="changeStatus(k)" v-tooltip="'Change Status'">
                        <i class="icon-settings"></i>
                      </a>
                      <a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action"
                        @click="deleteItem($event,k)" v-tooltip="'Remove'">
                        <i class="ti-trash"></i>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="NotFound">
                    <td colspan="99">
                      <h3 class="tc">{{NotFound}}</h3>
                    </td>
                  </tr>
                  <tr v-if="data.data===false">
                    <td colspan="99">
                      <LoadingSpinner light></LoadingSpinner>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
            <div class="card">
              <div class="panel-body">
                <div class="pull-right">
                  <Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-if="$route.params.id">
          <VForm @resp="submitForm" method="post">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-8">
                  <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
                </div>
              </div>
              <div class="info"></div>
              <div class="row">
                <div class="col-sm-5">
                  <BoField name="mpc_name" :attr="{minlength:'3'}" v-model="row.mpc_name" mandatory></BoField>
                  <small class="mb-2 d-inline-block text-info">{{validation('mpc_name').maxlength-mpTitleLength}} character remaining </small>
                  <BoField name="mpc_desc">
                    <textarea name="mpc_desc" rows="5" :attr="{minlength:'10'}" v-model="row.mpc_desc"
                      v-bind="validation('mpc_desc')" class="form-control"></textarea>
                  </BoField>
                  <BoField name="mpc_hero_title" v-model="row.mpc_hero_title"></BoField>
                  <BoField name="mpc_hero_sub_title" v-model="row.mpc_hero_sub_title"></BoField>
                  <!-- <BoField name="mpc_hero_color_title" :attr="{type:'color'}" v-model="row.mpc_hero_color_title">
                  </BoField> -->
                </div>
                <div class="col-sm-4">
                  <BoField name="mpc_hero_back_image">
                    <Uploader name="mpc_hero_back_image" type="back_hero_product" uploadType="cropping" mandatory
                      :param="{thumbnail:true}" v-model="row.mpc_hero_back_image"></Uploader>
                  </BoField>
                </div>
                <div class="col-sm-3">
                  <BoField name="mpc_hero_image">
                    <Uploader name="mpc_hero_image" type="hero_product" uploadType="cropping" :param="{thumbnail:true}"
                      v-model="row.mpc_hero_image"></Uploader>
                  </BoField>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-8">
                  <h5 class="card-title">Meta Content</h5>
                </div>
              </div>
              <div class="info"></div>
              <div class="row">
                <div class="col-sm-6">
                  <BoField name="mpc_meta_title" :attr="{minlength:'3'}" v-model="row.mpc_meta_title" mandatory>
                  </BoField>
                  <BoField name="mpc_meta_keyword" mandatory>
                    <TagsInput name="mpc_meta_keyword" v-model="row.mpc_meta_keyword"
                      :attr="validation('mpc_meta_keyword')">
                    </TagsInput>
                  </BoField>
                </div>
                <div class="col-sm-6">
                  <BoField name="mpc_meta_desc" mandatory>
                    <textarea name="mpc_meta_desc" rows="5" :attr="{minlength:'10'}" v-model="row.mpc_meta_desc"
                      v-bind="validation('mpc_meta_desc')" class="form-control"></textarea>
                  </BoField>
                </div>
              </div>
            </div>
            <div class="col-xs-12">
              <div class="text-right">
                <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form <i
                    class="icon-arrow-right14 position-right"></i></button>
              </div>
            </div>

          </VForm>
        </div>

      </div>
    </div>

    <div class="modal" id="viewbanner">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Banner Product</h4>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="wrap_banner border">
                  <img class="img1" src="http://placehold.it/900x300" />

                  <div class="warp_img_prod">
                    <img class="img2" src="http://placehold.it/300x200" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>